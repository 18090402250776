<template>
    <div class="card">
        <div class="bg-blue-light p-2">
            <TitleButton
                :showBtn="false"
                btnTitle="Add New"
                title="Payable Register"
            />
            <div class="row match-height">
                <div class="col-12 col-lg-12 col-xl-12 col-xxl-12 mt-2">
                    <DateQuerySetter
                        @onClickGo="getPayables"
                    >
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                            <label for="colFormLabel" class="col-form-label">Roles</label>
                            <v-select
                                placeholder="Select Roles"
                                v-model="company_role_id"
                                :options="companyRoles"
                                label="name"
                                :reduce="name => name.id"
                            />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                            <label class="form-label" for="party">Party</label>
                            <AsyncSelect
                                placeholder="Party Name, ID, Mobile No"
                                v-model="contactProfile"
                                :api-service="fetchContactProfiles"
                                :format-label="formatPatientLabel"
                            />
                        </div>
                    </DateQuerySetter>
                </div>
                <div class="d-flex justify-content-end px-2 mb-2">
                    <button class="btn btn-primary btn-block waves-effect waves-float waves-light print-btn" @click="goToPrint">
                        Print
                    </button>
                    <button class="btn btn-primary btn-block waves-effect waves-float waves-light" @click="exportReport">
                        Export
                    </button>
                </div>
            </div>
        </div>

        <div class="mt-2 px-2">
            <PayableTable
                :tableData="payable"
            />
        </div>

        <Loader v-if="loading"/>

    </div>
</template>

<script setup>
    import {inject, onMounted, ref} from 'vue';
    import {useRoute, useRouter} from 'vue-router'
    import Loader from "@/components/atom/LoaderComponent.vue";
    import TitleButton from '@/components/atom/TitleButton'
    import DateQuerySetter from '@/components/atom/DateQuerySetter'
    import PayableTable from '@/components/molecule/company/report/PayableTable.vue'
    import Pagination from "@/components/atom/Pagination.vue";
    import handleCompany from "@/services/modules/company";
    import handleRole from "@/services/modules/role";
    import useReport from '@/services/modules/dashboard/report.js'
    import { useStore } from 'vuex';
    import handleContact from '@/services/modules/contact'
    import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
    import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
    import pdfPrinter from '@/services/utils/pdf/payableRegister';

    const { fetchPayable } = useReport()
    const { fetchCompanyDefaultRoles } = handleRole();
    const { fetchContactProfiles } = handleContact();
    const { formatPatientLabel } = useAsyncDropdownHelper();
    const { fetchCompanyInfo } = handleCompany();
    const { exportToPDF, formatMoneyReceiptData } = pdfPrinter();

    const route = useRoute()
    const store = useStore();
    const router = useRouter();

    let offset = ref(200)
    const loading = ref(false);
    const showError = inject('showError');
    const payable = ref([]);
    const companyRoles = ref([])
    const company_role_id = ref(null)
    const contactProfile = ref(null);
    const companyInfo = ref(null);
    const isFetchAll = ref(false);
    const payablePdfData = ref([]);

    const goToPrint = async() => {

        const payload = {
            startDate: route.query.start,
            endDate: route.query.end,
            role: '',
            party: contactProfile.value ? contactProfile.value.name : '',
        };

        if(company_role_id.value){
            const role = companyRoles.value.find(item => item.id === company_role_id.value)
            payload.role = role.name;
        }

        isFetchAll.value = true;

        await getPayables().then(() => {
            if(!payablePdfData.value.length) return;
            const formattedData = formatMoneyReceiptData(payablePdfData.value)
            exportToPDF(companyInfo.value, formattedData, payload)
        })

        isFetchAll.value = false;
    }

    const getPayables = async () => {
        loading.value = true;
        try {
            const res = await fetchPayable(getQuery());

            if (!res.status) {
                payable.value = []
                return store.commit("resetPagination")
            }

            if(isFetchAll.value){
                payablePdfData.value = res.data;
                return;
            }

            payable.value = res.data.data;

            if (payable.value.length > 0) {
                setPagination(res.data)
            }

        } catch (err) {
            if (err.response) {
                showError(err.response.message)
            }
        } finally {
            loading.value = false;
        }
    }

    const getDefaulRoles = async () => {
        const companyQuery = `?company_id=${route.params.companyId}`
        const res = await fetchCompanyDefaultRoles(companyQuery)
        if(res.status) {
            companyRoles.value = res.data;
        }
    }

    const setPaginationData = (data) => {
        store.commit('setPaginationData', data)
    };

    const onPageChange = async (page) => {
        let routeQuery = Object.assign({}, route.query)
        routeQuery.page = page
        await router.push({path: route.path, query: routeQuery})
        await getPayables()
    }

    const setPagination = (data) => {
        setPaginationData({
            records: data.total,
            perPage: data.per_page,
            currentPage: data.current_page,
        })
    }

    const getQuery = () => {
        let query = '?company_id=' + route.params.companyId
        query += '&offset=' + offset.value
        if(company_role_id.value) query += '&company_role_id=' + company_role_id.value;
        if(contactProfile.value) query += '&contact_profile_id=' + contactProfile.value.id;
        if(isFetchAll.value) query += '&is_fetch_all=' + isFetchAll.value;
        if(!route.query.start || !route.query.end) return query
        return query+`&payable_date={
            "start": "${route.query.start}",
            "end": "${route.query.end}"
        }`
    }

    const exportReport = () => {
        let token = localStorage.getItem('token');
        let url = `${process.env.VUE_APP_BASE_URL}/export/accounting/payable${getQuery()}&_token=${token}&is_export=1`;
        let a = document.createElement('a');
        a.setAttribute('href', url);
        a.click();
    }

    const getCompany = async () => {
        await fetchCompanyInfo(route.params.companyId).then((res) => {
            companyInfo.value = res.data;
        })
    }

    onMounted(() => {
        getDefaulRoles();
        getCompany();
    })
</script>

<style scoped>
  .print-btn {
    margin-right: 1% !important;
  }
</style>
